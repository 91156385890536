import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSearch, FaRedoAlt } from 'react-icons/fa';
import { getShowingWithOutTime } from './Utility/Utility';

const VehicleEntry = () => {
    const [vehicleNo, setVehicleNo] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const [zoomedImage, setZoomedImage] = useState(null);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const tableCustomStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#001f3f',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
        rows: {
            style: {
                borderBottom: '1px solid #ddd',
                padding: '12px',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#f5f5f5',
                borderBottomColor: '#FFFFFF',
                outline: 'none',
            },
        },
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDateToLocal = (date) => {
        if (!date) return null;
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    };

    const fetchData = (vehicleNo,startDate,endDate) => {
        const formattedStartDate = formatDateToLocal(startDate);
        const formattedEndDate = formatDateToLocal(endDate);

        axios.post('https://vehiclenumber.arustu.com/api/Vehicle/VehicleInformation', {
            VehicleNo: vehicleNo,
            EntryDate: formattedStartDate,
            ExitDate: formattedEndDate,
        })
            .then(response => {
                const fetchedData = response.data.data || [];
                setData(fetchedData);
                setIsDataEmpty(fetchedData.length === 0);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setData([]);
                setIsDataEmpty(true);
            });
    };


    // const fetchData = () => {
    //     axios.post('https://vehiclenumber.arustu.com/api/Vehicle/VehicleInformation', {
    //         VehicleNo: vehicleNo,
    //         EntryDate: startDate,
    //         ExitDate: endDate,
    //     })
    //         .then(response => {
    //             const fetchedData = response.data.data || [];
    //             setData(fetchedData);
    //             setIsDataEmpty(fetchedData.length === 0);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching data:', error);
    //             setData([]);
    //             setIsDataEmpty(true);
    //         });
    // };
    console.log(data)

    const handleReset = () => {
        setVehicleNo('');
        setStartDate(null);
        setEndDate(null);
        fetchData();
    };

    const handleSearch = () => {
        fetchData(vehicleNo,startDate,endDate);
    };

    const columns = [
        {
            name: 'Vehicle No',
            selector: (row) => row?.VehicleNo || '',
            sortable: true
        },
        {
            name: 'Entry DateTime',
            // selector: (row) => row.Entrydate || '',
            selector: (row) => row.Entrydate ? getShowingWithOutTime(row.Entrydate) : '',
            sortable: true
        },
        {
            name: 'Exit DateTime',
            selector: (row) => row.Exitdate || '',
            sortable: true
        },
        {
            width: '200px',
            name: 'Entry Image',
            cell: row => (
                <img
                    src={row.Entrypath ? row.Entrypath.replace(/\\/g, "/") : 'default-image-url.jpg'}
                    alt="Entry"
                    className="img-thumbnail"
                    style={{ width: '100px', height: 'auto', borderRadius: '4px' }}
                    onClick={() => setZoomedImage(row.Entrypath ? row.Entrypath.replace(/\\/g, "/") : 'default-image-url.jpg')}
                />
            ),
            sortable: true,
        },
        {
            width: '200px',
            name: 'Exit Image',
            cell: row => (
                <img
                    src={row.Exitpath ? row.Exitpath.replace(/\\/g, "/") : 'default-image-url.jpg'}
                    alt="Exit"
                    className="img-thumbnail"
                    style={{ width: '100px', height: 'auto', borderRadius: '4px' }}
                />
            ),
            sortable: true,
        },
        {
            name: 'Total Time',
            selector: (row) => row.TotalTime || 'N/A',
            sortable: true
        },
    ];

    return (
        <div className="container-fluid mt-4">
            <div className="card shadow-sm">
                <div className="card-body">
                    {/* Filter Section */}
                    <div className="row mb-4">
                        <div className="col-12 col-md-4 mt-2">
                            <input
                                type="text"
                                name="vehicleNo"
                                className="form-control"
                                placeholder="Filter by Vehicle No"
                                value={vehicleNo}
                                onChange={(e) => setVehicleNo(e.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-2 mt-2">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date);
                                    setEndDate(null);
                                }}
                                placeholderText="Start Date"
                                className="form-control"
                                dateFormat="MM/dd/yyyy "
                                isClearable={startDate ? true : false}
                            />
                        </div>
                        <div className="col-12 col-md-2 mt-2">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="End Date"
                                className="form-control"
                                minDate={startDate}
                            // maxDate={new Date()}

                            />
                        </div>
                        <div className="col-12 col-md-4 mt-2 d-flex align-items-center">
                            <button className="btn btn-primary mr-2" onClick={handleSearch}>
                                <FaSearch className="mr-1" /> Search
                            </button>
                            <button className="btn btn-secondary" onClick={handleReset}>
                                <FaRedoAlt className="mr-1" /> Reset
                            </button>
                        </div>
                    </div>
                    {/* Filter Section */}

                    {/* Table Section */}
                    {isDataEmpty ? (
                        <div className="text-center mt-4">
                            <h5>No data available for the selected filters.</h5>
                        </div>
                    ) : (
                        <div className="d-none d-md-block mt-2">
                            <DataTable
                                dense
                                columns={columns}
                                data={data}
                                selectableRowsHighlight
                                highlightOnHover
                                customStyles={tableCustomStyles}
                                fixedHeader
                                persistTableHead={true}
                                pagination
                            />
                        </div>
                    )}

                    {/* Zoomed Image Modal */}
                    {zoomedImage && (
                        <div className="zoomed-image" onClick={() => setZoomedImage(null)}>
                            <div className="image-container" onClick={(e) => e.stopPropagation()}>
                                <span className="close-btn" onClick={(e) => { e.stopPropagation(); setZoomedImage(null); }}>×</span>
                                <img
                                    src={zoomedImage}
                                    alt="Zoomed"
                                    className="img-fluid"
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="d-block d-md-none mt-2">
                <div className="card shadow-sm border-0 mb-4">
                    <div className="card-body p-4">
                        {data?.map((vehicle, index) => (
                            <div key={index} className="vehicle-entry mb-4 ">
                                <div className="d-flex justify-content-between mb-3">
                                    <h6 className="font-weight-bold text-dark m-h6">Vehicle No</h6>
                                    <span className="text-secondary">{vehicle.VehicleNo}</span>
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between mb-3">
                                    <h6 className="font-weight-bold text-dark m-h6">Entry DateTime</h6>
                                    <span className="text-secondary">{vehicle.Entrydate}</span>
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between mb-3">
                                    <h6 className="font-weight-bold text-dark m-h6">Exit DateTime</h6>
                                    <span className="text-secondary">{vehicle.Exitdate || 'N/A'}</span>
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between mb-3">
                                    <h6 className="font-weight-bold text-dark m-h6">Entry Image</h6>
                                    <img
                                        src={vehicle.Entrypath ? vehicle.Entrypath.replace(/\\/g, "/") : 'default-image-url.jpg'}
                                        alt="Entry"
                                        style={{ width: '40px', height: 'auto', cursor: 'pointer' }}
                                        onClick={() => setZoomedImage(vehicle.Entrypath)}
                                    />
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between mb-3">
                                    <h6 className="font-weight-bold text-dark m-h6">Exit Image</h6>
                                    <img
                                        src={vehicle.Exitpath ? vehicle.Exitpath.replace(/\\/g, "/") : 'default-image-url.jpg'}
                                        alt="Exit"
                                        style={{ width: '40px', height: 'auto', cursor: 'pointer' }}
                                        onClick={() => setZoomedImage(vehicle.Exitpath)}
                                    />
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold text-dark m-h6">Total Time</h6>
                                    <span className="text-secondary">{vehicle.TotalTime}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehicleEntry;
